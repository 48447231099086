import {
  PharmacyLocationDto,
  NormalTimeRangeDto,
  PharmacyDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { LocationConfigDto } from '@digitalpharmacist/prescription-service-client-axios';
import create from 'zustand';
import pharmacyService from '../api/pharmacy-service';
import { PHARMACY_ID, PHARMACY_NAME } from '../common/constants';
import { DeepLinkingConfig } from 'assets/core/deep-linking';
import unifiedCommsService from '../api/unified-comms-service';
import { useUserState } from './user-store';
import { UserNotificationDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { logError } from 'assets/logging/logger';

export interface AppState {
  pharmacyId: string;
  pharmacyName: string;
  pharmacy: PharmacyDto;
  deepLinkingConfig?: DeepLinkingConfig;
  stores: PharmacyLocationDto[];
  getStores: () => Promise<void>;
  locationStoreHours: NormalTimeRangeDto[];
  locationPharmacyHours: NormalTimeRangeDto[];
  locationLanguages: string[];
  locationServices: string[];
  isMobileWeb?: boolean;
  notifications: UserNotificationDto[];
  locationPrescriptionConfig: LocationConfigDto;
  refreshNotifications: () => Promise<void>;
}

export const useAppStateStore = create<AppState>((set) => ({
  pharmacyId: PHARMACY_ID,
  pharmacyName: PHARMACY_NAME,
  pharmacy: {} as PharmacyDto,
  stores: [],
  getStores: async () => {
    const response = await pharmacyService.findLocationsByPharmacyId();
    set({ stores: response.locations });
  },
  locationPrescriptionConfig: {} as LocationConfigDto,
  locationStoreHours: [],
  locationPharmacyHours: [],
  locationLanguages: [],
  locationServices: [],
  notifications: [],
  refreshNotifications: async () => {
    const { user } = useUserState.getState();
    if (!user) {
      logError(
        new Error('Failed to get notitifications list, user is not defined.'),
      );
      return;
    }
    try {
      const response = await unifiedCommsService.getNotifications(user.id);
      //TODO: remove this filter once the backend is modified to do this filtering
      set({ notifications: response.filter((n) => !n.is_hidden) });
    } catch (e) {
      logError(new Error('Failed to get notifications list', e as Error));
    }
  },
}));
