import { PlatformType } from 'assets/components/types';
import { CreateConversationDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  BillingCircleIcon,
  CalendarCircleIcon,
  GeneralMessageCircleIcon,
  MedicationCircleIcon,
} from 'assets/icons';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

export interface NewMessageProps {
  platform?: PlatformType;
  onMessageCreate?: () => void;
  preSelectedSubject?: string;
  handleMessageSend?: (
    pharmacyId: string,
    locationId: string,
    patientRecord: PatientRecordDto,
    createConversationDto: CreateConversationDto,
    locationPatientId?: string,
  ) => void;
}

export interface NewMessageHandler {
  show: () => void;
  hide: () => void;
}

export interface NewMessageForm {
  message: string;
  subject: string;
}

export enum SubjectOptionsEnum {
  General = 'General',
  Billing = 'Billing',
  MedicationInfo = 'Medication Info',
  SideEffects = 'Side Effects',
  Appointments = 'Appointments',
}

export const SubjectOptions: Record<string, React.FunctionComponent> = {
  [SubjectOptionsEnum.General]: GeneralMessageCircleIcon,
  [SubjectOptionsEnum.Billing]: BillingCircleIcon,
  [SubjectOptionsEnum.MedicationInfo]: MedicationCircleIcon,
  [SubjectOptionsEnum.SideEffects]: GeneralMessageCircleIcon,
  [SubjectOptionsEnum.Appointments]: CalendarCircleIcon,
};
