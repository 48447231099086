import React, {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useEffect,
} from 'react';
import { GradientView } from 'assets/components/gradient-view';
import { Platform, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { LocationIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import { uuid } from 'assets/utils/uuid';
import { IconButton } from 'assets/components/icon-button';
import { StoreSelector } from '../store-selector';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { StoreSelectorHandler } from '../store-selector/types';
import { getText } from 'assets/localization/localization';
import { useIsFocused } from '@react-navigation/native';
import { NotificationBadge } from 'assets/components/notifications';
import { NotificationsListNative } from '../notifications-list';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

export const LandingHeader: FunctionComponent<
  PropsWithChildren<LandingHeaderProps>
> = ({ pharmacyName, greetings, patientName, gradientId = uuid() }) => {
  const theme = useTheme();
  const styles = useStyles();
  const gradientIdRef = useRef(gradientId);
  const storeSelectorRef = useRef<StoreSelectorHandler>(null);

  const { user, updateUser } = useUserState();
  const { stores, getStores, notifications } = useAppStateStore();

  const notificationCount = notifications.filter(
    (notification) => !notification.is_read,
  ).length;

  const isMultiStore = stores.length > 1;

  const isFocused = useIsFocused();
  let defaultGreeting = '';

  const getDefaultGreeting = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    //This is here so that the greeting is "good evening" until 12:01
    if (currentHour === 0 && currentMinute < 1) {
      return getText('good-evening');
    } else if (currentHour === 0 && currentMinute >= 1) {
      return getText('good-morning');
    } else if (currentHour < 12) {
      return getText('good-morning');
    } else if (currentHour < 18) {
      return getText('good-afternoon');
    } else {
      return getText('good-evening');
    }
  };

  // Set the default greeting when the screen is focused
  if (isFocused) {
    !greetings && (defaultGreeting = getDefaultGreeting());
  }

  // first rendering only
  useEffect(() => {
    if (stores.length < 1) getStores();
  }, []);

  const handleOnLocationChanged = (value: PharmacyLocationDto) => {
    updateUser({ preferredPharmacyLocationId: value.id });
  };

  const handleOnStoreIconPress = () => {
    storeSelectorRef.current?.show();
  };

  const notificationsBottomSheetRef = useRef<BottomSheetModal>(null);

  const handleNotificationsBadgePress = () => {
    notificationsBottomSheetRef.current?.present();
  };

  return (
    <>
      <GradientView
        contentHeight={Platform.OS === 'ios' ? 160 : 140}
        fillGradientStops={[
          { stopColor: theme.colors.gradientStart, offset: 0 },
          { stopColor: theme.colors.gradientEnd, offset: 100 },
        ]}
        gradientId={gradientIdRef.current}
      >
        <View style={styles.headerContainer}>
          <View style={styles.leftContent}>
            <Text
              style={{
                color: theme.palette.gray[200],
                //fontSize: 18,
                fontWeight: '500',
              }}
            >
              {pharmacyName}
            </Text>
            <Text
              style={{
                color: theme.palette.white,
                fontSize: 24,
                fontWeight: '400',
              }}
            >
              {greetings || defaultGreeting}
            </Text>
            {patientName && (
              <Text style={styles.patientNameText}>{patientName}</Text>
            )}
          </View>

          <View style={styles.rightContent}>
            {notificationCount > 0 && (
              <NotificationBadge
                count={notificationCount}
                onPress={handleNotificationsBadgePress}
              />
            )}
            <NotificationsListNative
              bottomSheetRef={notificationsBottomSheetRef}
              notifications={notifications}
            />
            {isMultiStore && (
              <IconButton
                icon={LocationIcon}
                logger={{ id: '' }}
                onPress={handleOnStoreIconPress}
                color={theme.palette.white}
              />
            )}
          </View>
        </View>
      </GradientView>
      {isMultiStore && (
        <StoreSelector
          ref={storeSelectorRef}
          options={stores}
          selectedOption={stores.find(
            (x) => x.id === user?.preferredPharmacyLocationId,
          )}
          onChange={handleOnLocationChanged}
          storeInformationShown={false}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'flex-end',
    position: 'relative',
    paddingBottom: theme.getSpacing(1),
  },
  leftContent: {
    alignItems: 'flex-start',
    paddingLeft: theme.getSpacing(2),
    marginRight: theme.getSpacing(4),
  },
  rightContent: {
    position: 'absolute',
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(0.5),
  },
  patientNameText: {
    color: theme.palette.white,
    fontSize: 28,
    fontWeight: '700',
    fontFamily: 'Lato_700Bold',
  },
}));

export interface LandingHeaderProps {
  pharmacyName?: string;
  greetings?: string;
  patientName?: string;
  gradientId?: string;
}

export const NativeHeaderTestIDs = {
  title: 'native-header-title',
  actionButton: 'native-header-action-button',
  backButton: 'native-header-back-button',
};
