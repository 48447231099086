import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Modal } from 'assets/components/modal';
import {
  NewMessageHandler,
  NewMessageProps,
  SubjectOptionsEnum,
} from './MessageProps';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Form } from 'assets/layout';
import { getSpacing, makeStyles } from 'assets/theme';
import { StoreInformation } from '../../components/store-selector/StoreInformation';
import { useForm } from 'react-hook-form';
import { NewMessageForm } from './MessageProps';
import { StoreSelector } from '../../components/store-selector';
import { StoreSelectorHandler } from '../../components/store-selector/types';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { NewMessageRadioButton } from './NewMessageRadioButton';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import patientService from '../../api/patient-service';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { TextField } from 'assets/components/text-field';
import { MESSAGE_LIMIT } from '../../../../pharmacy/modules/screens/messages/data';

export const NewMessageModalComponent = forwardRef<
  NewMessageHandler,
  NewMessageProps
>(
  (
    { handleMessageSend = async () => {}, preSelectedSubject }: NewMessageProps,
    ref: ForwardedRef<NewMessageHandler>,
  ) => {
    const [showModal, setShowModal] = useState(false);
    const [currentStore, setCurrentStore] = useState<PharmacyLocationDto>();
    const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
    const storeSelectorRef = useRef<StoreSelectorHandler>(null);
    const { user, updateUser } = useUserState();
    const storeId = user?.preferredPharmacyLocationId;
    const isFocused = useIsFocused();
    const { stores } = useAppStateStore();
    const styles = useStyles();

    useEffect(() => {
      setCurrentStore(stores.find((x) => x.id === storeId));

      void (async () => {
        if (user?.patientRecordId) {
          const patientRecordData = await patientService.findPatientRecord(
            user.patientRecordId,
          );

          const locationPatientRecord =
            patientRecordData.location_patient_records.find(
              (record) =>
                record.location_id === user.preferredPharmacyLocationId,
            );
          setPatientRecord(patientRecordData);
          await updateUser({
            preferredPharmacyLprId: locationPatientRecord?.id,
          });
        }
      })();
    }, [storeId, stores, isFocused]);

    const methods = useForm<NewMessageForm>({
      defaultValues: {
        message: '',
        subject: preSelectedSubject
          ? preSelectedSubject
          : SubjectOptionsEnum.General,
      },
    });

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleDismissModal = () => {
      setShowModal(false);
      methods.reset();
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const handleStoreSelectorChange = () => {
      storeSelectorRef.current?.show();
    };

    const handleOnLocationChanged = (value: PharmacyLocationDto) => {
      updateUser({ preferredPharmacyLocationId: value.id });
    };

    const handleOnMessageChange = (value: string) => {
      methods.setValue('message', value);
    };

    return (
      <Modal
        isScrollable={true}
        size="sm"
        title={getText('new-message')}
        show={showModal}
        cancelButtonProps={{
          onPress: handleDismissModal,
          logger: { id: 'new-message-modal-cancel-button' },
        }}
        okButtonProps={{
          text: getText('send'),
          onPress: methods.handleSubmit((data) => {
            if (user && currentStore && patientRecord) {
              handleMessageSend(
                user.pharmacyId,
                currentStore.id,
                patientRecord,
                {
                  author_id: user.id,
                  author_type: AuthorType.Patient,
                  patient_viewed_all_messages: true,
                  pharmacy_viewed_all_messages: false,
                  subject: data.subject,
                  content: data.message,
                  attachments: [], // TODO: implement actual attachment feature
                },
                user.preferredPharmacyLprId,
              );
            }
          }),
          logger: { id: 'new-message-modal-send-button' },
        }}
      >
        <View style={styles.container}>
          <Form methods={methods}>
            {currentStore && (
              <View style={styles.currentStoreContainer}>
                <StoreInformation
                  showPhone={false}
                  showChange={true}
                  item={currentStore}
                  onChangePress={handleStoreSelectorChange}
                />
              </View>
            )}
            <TextField
              name="message"
              multiline={true}
              numberOfLines={5}
              placeholder={getText('new-message-placeholder')}
              onChange={(value) => handleOnMessageChange(value)}
              maxLength={MESSAGE_LIMIT}
              rules={{
                required: getText('message-body-is-required'),
              }}
            />
            <View style={styles.messageLimitWrapper}>
              <Text style={styles.messageLimit}>{`${
                methods.watch('message').length
              }/${MESSAGE_LIMIT}`}</Text>
            </View>
            <View>
              <Text>{getText('message-subject-label')}</Text>
            </View>
            <View style={styles.radioButtonsContainer}>
              <>
                <View style={styles.radioButtonsInnerContainer}>
                  <NewMessageRadioButton
                    selected={
                      methods.watch('subject') === SubjectOptionsEnum.General
                    }
                    text={getText('general')}
                    value={SubjectOptionsEnum.General}
                    onPress={() => {
                      methods.setValue('subject', SubjectOptionsEnum.General);
                    }}
                    disabled={
                      preSelectedSubject
                        ? preSelectedSubject !== SubjectOptionsEnum.General
                        : undefined
                    }
                  />
                  <NewMessageRadioButton
                    selected={
                      methods.watch('subject') ===
                      SubjectOptionsEnum.Appointments
                    }
                    text={getText('appointments')}
                    value={SubjectOptionsEnum.Appointments}
                    onPress={() => {
                      methods.setValue(
                        'subject',
                        SubjectOptionsEnum.Appointments,
                      );
                    }}
                    disabled={
                      preSelectedSubject
                        ? preSelectedSubject !== SubjectOptionsEnum.Appointments
                        : undefined
                    }
                  />
                </View>
                <View style={styles.radioButtonsInnerContainer}>
                  <NewMessageRadioButton
                    selected={
                      methods.watch('subject') === SubjectOptionsEnum.Billing
                    }
                    text={getText('billing')}
                    value={SubjectOptionsEnum.Billing}
                    onPress={() => {
                      methods.setValue('subject', SubjectOptionsEnum.Billing);
                    }}
                    disabled={
                      preSelectedSubject
                        ? preSelectedSubject !== SubjectOptionsEnum.Billing
                        : undefined
                    }
                  />
                  <NewMessageRadioButton
                    selected={
                      methods.watch('subject') ===
                      SubjectOptionsEnum.SideEffects
                    }
                    text={getText('side-effects')}
                    value={SubjectOptionsEnum.SideEffects}
                    onPress={() => {
                      methods.setValue(
                        'subject',
                        SubjectOptionsEnum.SideEffects,
                      );
                    }}
                    disabled={
                      preSelectedSubject
                        ? preSelectedSubject !== SubjectOptionsEnum.SideEffects
                        : undefined
                    }
                  />
                </View>
              </>
              <NewMessageRadioButton
                selected={
                  methods.watch('subject') === SubjectOptionsEnum.MedicationInfo
                }
                text={getText('med-info')}
                value={SubjectOptionsEnum.MedicationInfo}
                onPress={() => {
                  methods.setValue(
                    'subject',
                    SubjectOptionsEnum.MedicationInfo,
                  );
                }}
                disabled={
                  preSelectedSubject
                    ? preSelectedSubject !== SubjectOptionsEnum.MedicationInfo
                    : undefined
                }
              />
            </View>
          </Form>
          <StoreSelector
            ref={storeSelectorRef}
            options={stores}
            selectedOption={stores.find(
              (x) => x.id === user?.preferredPharmacyLocationId,
            )}
            onChange={handleOnLocationChanged}
            storeInformationShown={false}
          />
        </View>
      </Modal>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: getSpacing(1),
  },
  currentStoreContainer: {
    paddingBottom: getSpacing(3),
    paddingLeft: getSpacing(1),
  },
  radioButtonsContainer: {
    paddingTop: getSpacing(2),
    flexDirection: 'row',
  },
  radioButtonsInnerContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  messageLimitWrapper: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: getSpacing(0.5),
    marginBottom: getSpacing(3),
  },
  messageLimit: {
    color: theme.palette.gray[500],
  },
}));
