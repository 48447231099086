import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import { Platform } from 'react-native';
import { NewMessageHandler, NewMessageProps } from './MessageProps';
import { NewMessageBottomSheet } from './NewMessageBottomSheet';
import { NewMessageModalComponent } from './NewMessageModalComponent';
import UnifiedCommsService from '../../api/unified-comms-service';
import { CreateConversationDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { createLprIfNotExist } from '../../utils';

const NewMessageComponent: ForwardRefRenderFunction<
  NewMessageHandler,
  NewMessageProps
> = (
  { platform = Platform.OS, preSelectedSubject, ...rest }: NewMessageProps,
  ref: ForwardedRef<NewMessageHandler>,
) => {
  const newMessageRef = React.useRef<NewMessageHandler>(null);

  const handleShow = () => {
    newMessageRef.current?.show();
  };

  const handleHide = () => {
    newMessageRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleMessageSend = async (
    pharmacyId: string,
    locationId: string,
    patientRecord: PatientRecordDto,
    createConversationDto: CreateConversationDto,
    locationPatientId?: string,
  ) => {
    const finalLocationPatientId = await createLprIfNotExist(
      locationPatientId,
      locationId,
      patientRecord,
    );

    await UnifiedCommsService.createConversation(
      pharmacyId,
      locationId,
      finalLocationPatientId,
      createConversationDto,
    );

    handleHide();
    if (rest.onMessageCreate) {
      rest.onMessageCreate();
    }
  };

  return (
    <>
      {platform === 'web' ? (
        <NewMessageModalComponent
          {...rest}
          ref={newMessageRef}
          handleMessageSend={handleMessageSend}
          preSelectedSubject={preSelectedSubject}
        />
      ) : (
        <NewMessageBottomSheet
          {...rest}
          ref={newMessageRef}
          handleMessageSend={handleMessageSend}
          preSelectedSubject={preSelectedSubject}
        />
      )}
    </>
  );
};

export const NewMessage = forwardRef<NewMessageHandler, NewMessageProps>(
  NewMessageComponent,
);
