import { AxiosRequestConfig } from 'axios';
import { UNIFIED_COMMS_SERVICE_BASE_URL } from '../common/constants';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  DefaultApi as UnifiedCommsClient,
  ConversationDto,
  DirectMessageDto,
  UpdateUserViewedStatusDto,
  CreateConversationDto,
  DirectMessagePatientDto,
  AddDirectMessageDto,
  DirectMessageExtendedResponseDto,
  UserNotificationDto,
  UpdateUserNotificationDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IUnifiedCommsService {}

export class UnifiedCommsService
  extends BaseApiService
  implements IUnifiedCommsService
{
  private unifiedCommsServiceClient: UnifiedCommsClient;
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.unifiedCommsServiceClient = new UnifiedCommsClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async getAllConversationsByPatientAndLocation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
  ): Promise<DirectMessagePatientDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllConversationsByPatientAndLocation(
        xPharmacyId,
        locationId,
        locationPatientId,
      );
    return data ?? [];
  }

  async getAllMessagesByConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessageExtendedResponseDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllMessagesByConversation(
        xPharmacyId,
        locationId,
        locationPatientId,
        conversationId,
      );
    return data;
  }

  async updateUserViewedStatus(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    updateUserViewedStatusDto: UpdateUserViewedStatusDto,
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageUpdateUserViewedStatus(
        xPharmacyId,
        locationId,
        locationPatientId,
        conversationId,
        updateUserViewedStatusDto,
      );
    return data;
  }

  async createConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    createConversationDto: CreateConversationDto,
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageCreateConversation(
        xPharmacyId,
        locationId,
        locationPatientId,
        createConversationDto,
      );
    return data;
  }

  async createMessage(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    addDirectMessageDto: AddDirectMessageDto,
  ): Promise<DirectMessageDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageAddMessage(
        xPharmacyId,
        locationId,
        locationPatientId,
        conversationId,
        addDirectMessageDto,
      );
    return data;
  }

  async getConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessagePatientDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetConversation(
        xPharmacyId,
        locationId,
        locationPatientId,
        conversationId,
      );
    return data;
  }

  async registerPushToken(user_id: string, push_token: string): Promise<void> {
    await this.unifiedCommsServiceClient.userPushTokenCreate({
      user_id: user_id,
      push_token: push_token,
    });
  }

  async getNotifications(user_id: string): Promise<UserNotificationDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.userNotificationFindByUserId(
        user_id,
      );
    return data;
  }

  async updateUserNotification(
    user_id: string,
    notification_id: string,
    updateUserNotificationDto: UpdateUserNotificationDto,
  ) {
    await this.unifiedCommsServiceClient.userNotificationUpdate(
      user_id,
      notification_id,
      updateUserNotificationDto,
    );
  }
}

export default new UnifiedCommsService(
  UNIFIED_COMMS_SERVICE_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
