import unifiedCommsService from '../../../api/unified-comms-service';
import { useUserState } from '../../../store/user-store';
import { logError } from 'assets/logging/logger';

export const handleMarkAsRead = async (notificationId: string) => {
  const { user } = useUserState.getState();
  if (!user) {
    logError(new Error('Failed to mark notification as read, user not found.'));
    return;
  }
  try {
    await unifiedCommsService.updateUserNotification(user.id, notificationId, {
      is_read: true,
    });
  } catch (e) {
    logError(new Error('Failed to mark notification as read', e as Error));
  }
};

export const handleMarkAsUnread = async (notificationId: string) => {
  const { user } = useUserState.getState();
  if (!user) {
    logError(
      new Error('Failed to mark notification as unread, user not found.'),
    );
    return;
  }
  try {
    await unifiedCommsService.updateUserNotification(user.id, notificationId, {
      is_read: false,
    });
  } catch (e) {
    logError(new Error('Failed to mark notification as unread', e as Error));
  }
};

export const handleMarkAsHidden = async (notificationId: string) => {
  const { user } = useUserState.getState();
  if (!user) {
    logError(
      new Error('Failed to mark notification as hidden, user not found.'),
    );
    return;
  }
  try {
    await unifiedCommsService.updateUserNotification(user.id, notificationId, {
      is_hidden: true,
    });
  } catch (e) {
    logError(new Error('Failed to mark notification as hidden', e as Error));
  }
};
