import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { StoreSelectorItem } from './StoreSelectorItem';
import { StoreSelectorListHandler, StoreSelectorListProps } from '../types';
import { Distance } from '../../../types';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { useStoresWithDistanceInfo } from '../hooks/useStoresWithDistanceInfo';
import { useLocation } from '../../../hooks/useLocation';

export const StoreSelectorList = forwardRef<
  StoreSelectorListHandler,
  StoreSelectorListProps
>(
  (
    {
      options = [],
      selectedOption,
      disabled = false,
      onChange,
      searchCriteria,
      onInfoButtonPress,
      searchAction,
    }: StoreSelectorListProps,
    ref,
  ) => {
    const styles = useStyles();
    const theme = useTheme();

    const { status } = useLocation();

    const { stores } = useStoresWithDistanceInfo(
      options,
      searchCriteria,
      searchAction,
    );

    const [currentSelectedLocation, setCurrentSelectedLocation] =
      useState(selectedOption);

    useImperativeHandle(ref, () => ({
      getSelectedValue: () => currentSelectedLocation,
    }));

    const handleStoreItemChange = (item: Distance<PharmacyLocationDto>) => {
      setCurrentSelectedLocation(item as PharmacyLocationDto);
      onChange?.(item as PharmacyLocationDto);
    };

    const handleOnInfoButtonPress = (item: Distance<PharmacyLocationDto>) => {
      onInfoButtonPress?.(item as PharmacyLocationDto);
    };

    useEffect(() => {
      if (selectedOption) setCurrentSelectedLocation(selectedOption);
    }, [selectedOption]);

    return (
      <View style={{ flexGrow: 1 }}>
        {selectedOption && (
          <View style={{ marginTop: theme.getSpacing(2) }}>
            <Text style={styles.bottomSheetViewSectionsTitle}>
              {getText('store-selector-your-store')}
            </Text>
            <StoreSelectorItem
              item={stores.find((x) => x.id === selectedOption.id)!}
              selected={
                // finding the selected store from 'stores' list
                // which have the distance information
                stores.find((x) => x.id === selectedOption.id)?.id ===
                selectedOption.id
              }
              disabled={disabled}
              onPress={handleStoreItemChange}
              status={status}
              onInfoButtonPress={handleOnInfoButtonPress}
            />
            <View style={styles.bottomSheetViewSectionsSeparator} />
          </View>
        )}

        <View style={{ marginTop: theme.getSpacing(1) }}>
          <Text style={styles.bottomSheetViewSectionsTitle}>
            {getText('store-selector-nearby-store')}
          </Text>
          {stores.map((x, index) => (
            <StoreSelectorItem
              item={x}
              selected={currentSelectedLocation?.id === x.id}
              disabled={disabled}
              onPress={handleStoreItemChange}
              key={index}
              status={status}
              onInfoButtonPress={handleOnInfoButtonPress}
            />
          ))}
        </View>
      </View>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  selectedAddressTitle: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[900],
  },
  selectedAddressSubTitle: {
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  changeButton: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.primary[500],
  },
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  bottomSheetViewSectionsTitle: {
    ...theme.fonts.medium,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '600',
    color: theme.palette.gray[700],
  },
  bottomSheetViewSectionsSeparator: {
    borderColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  bottomSheetViewButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  bottomSheetViewCloseButton: { flexGrow: 1, marginRight: theme.getSpacing(1) },
  bottomSheetViewSelectButton: { flexGrow: 1, marginLeft: theme.getSpacing(1) },
}));
