import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { useForm } from 'assets/form';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { getText } from 'assets/localization/localization';
import { Form, ScreenContainer } from 'assets/layout';
import { View } from 'react-native';
import { PatientUnderCareForm } from './patient-actions';
import { usePatientRecordState } from './patient-store';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { PatientUnderCareStackParamList } from './PatientUnderCareNavigation';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import patientService from '../../../api/patient-service';
import {
  Gender,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { MedicalInfoFormFields } from '../../../components/medical-info/MedicalInfoFormFields';
import {
  DateTimeFormat,
  formatISODateAsString,
} from '../../../common/form-utils';

export const AddMinorOrPetPatient: FunctionComponent<
  PropsWithChildren<AddMinorOrPetPatientProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { status, error, data, recordUnderCarePR } = usePatientRecordState();

  const methods = useForm<PatientUnderCareForm>({
    defaultValues: {
      first_name: recordUnderCarePR?.first_name,
      last_name: recordUnderCarePR?.last_name,
      date_of_birth: recordUnderCarePR?.date_of_birth,
      relationship: data?.relationship,
      email: '',
      allergies: [],
      prefers_easy_open_bottle_caps: undefined,
      prefers_med_sync: '',
    },
  });

  const handleSubmit = async () => {
    const patientMedicalInfos: UpdatePatientRecordDto = {
      gender: methods.getValues().gender,
      allergies: methods.getValues().allergies,
      medical_conditions: methods.getValues().medical_conditions,
      prefers_easy_open_bottle_caps:
        methods.getValues().prefers_easy_open_bottle_caps,
      prefers_med_sync:
        methods.getValues().prefers_med_sync === 'yes' ? true : false,
    };
    if (!recordUnderCarePR) throw new Error('No patient id');
    await patientService.updatePatientRecord(
      recordUnderCarePR.id,
      patientMedicalInfos,
    );
    navigation.navigate('add-minor-or-pet-request-submitted');
  };

  return (
    <ScreenContainer>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <View style={styles.requestTextContainer}>
          <Text style={styles.pucContainer}>
            {`${methods.getValues('first_name')} ${methods.getValues(
              'last_name',
            )}`.trim()}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.dobStyle}>
              {formatISODateAsString(
                methods.getValues('date_of_birth'),
                DateTimeFormat.USDateFormat,
              )}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
              <Text> {getText('gender')}</Text>
              <RadioButtonGroupField
                name="gender"
                testID={''}
                values={[
                  {
                    text: getText('male'),
                    value: Gender.Male,
                  },
                  {
                    text: getText('female'),
                    value: Gender.Female,
                  },
                ]}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <MedicalInfoFormFields />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <View style={styles.headerTitle}>
                <Text style={styles.headerTitleText}>
                  {getText('more-information')}
                </Text>
              </View>
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
              <Text>{getText('monthly-prescriptions')}</Text>
              <RadioButtonGroupField
                name="prefers_med_sync"
                testID={'monthly-prescriptions-id'}
                values={[
                  {
                    text: getText('yes'),
                    value: 'yes',
                  },
                  {
                    text: getText('no'),
                    value: 'no',
                  },
                ]}
              />
            </Form.Column>
          </Form.Row>
          <Form.Actions>
            <Button
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === 'loading'}
              logger={{ id: 'add-minor-patient-details-next-button' }}
            >
              {getText('next')}
            </Button>
          </Form.Actions>
        </Form>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    backgroundColor: theme.palette.gray[100],
    width: '100%',
  },
  pucContainer: {
    fontWeight: '600',
    fontSize: 24,
    lineHeight: 26,
    color: theme.palette.gray[700],
  },
  dobStyle: {
    color: theme.palette.gray[700],
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  headerTitle: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  headerTitleText: {
    ...theme.fonts.medium,
    marginBottom: theme.getSpacing(1),
    fontSize: 16,
    lineHeight: 24,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  text: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(2),
    paddingStart: theme.getSpacing(1),
    paddingEnd: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
  },
  requestTextContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
}));

type AddMinorOrPetPatientProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  'add-minor-or-pet-patient'
>;
